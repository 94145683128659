import React, { useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Logo from "assets/images/svg/everycred-logo.svg";
import { ReactComponent as Dashboard } from "assets/images/svg/Insights.svg";
import { ReactComponent as Credentials } from "assets/images/svg/Credentials.svg";
import { ReactComponent as Issuers } from "assets/images/svg/Issuers.svg";
import { ReactComponent as Templates } from "assets/images/svg/Templates.svg";
import { ReactComponent as Logs } from "assets/images/svg/Logs.svg";
import { ReactComponent as Settings } from "assets/images/svg/Settings.svg";
import { ReactComponent as About } from "assets/images/svg/About.svg";
import { ReactComponent as Logout } from "assets/images/svg/Logout.svg";
import { ReactComponent as HolderDidIcon } from "assets/images/svg/holder-did-white.svg";
import { useDispatch } from "react-redux";
import { toggleLogoutModal, toggleSideBar } from "store/commonActions";
import { useHistory, useLocation, NavLink } from "react-router-dom";
import { changeOrderBy, changePage } from "../Midbar/midbar.action";

const useStyles = makeStyles((theme) => ({
  paperProps: {
    width: "60%",
  },
  infiniteScrollerDiv: {
    overflowY: "auto",
  },
  siteAside: {
    position: "fixed",
    top: 0,
    left: 0,
    height: "100vh",
    zIndex: 4,
    "& ::-webkit-scrollbar": {
      width: 0,
      height: 0,
      background: "transperent",
    },
    [theme.breakpoints.down("xl")]: {
      width: "100px",
    },
    [theme.breakpoints.up("xl")]: {
      width: "106px",
    },
  },
  sidebarLeftSide: {
    borderRight: "none",
    boxShadow: "1.5px 1.5px 3px 0 rgba(0, 0, 0, 0.1)",
    background: "#f6f6f6",
    position: "relative",
    [theme.breakpoints.down("xl")]: {
      padding: "15px 15px 0",
    },
    [theme.breakpoints.up("xl")]: {
      padding: "26px 18px 18px",
    },
  },
  sidebarLogo: {
    padding: "0",
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
    [theme.breakpoints.down("xl")]: {
      margin: "0 auto 70px",
      width: "36px",
      height: "44px",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "20px auto 30px",
      width: 80,
      height: 70,
    },
    [theme.breakpoints.down("xs")]: {
      margin: "10px 0 20px",
      width: "100%",
      // height: "100%",
    },
    [theme.breakpoints.up("xl")]: {
      margin: "0 auto 60px",
      width: "42px",
      height: "48px",
    },
    "& img": {
      width: "100%",
      height: 47,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
      [theme.breakpoints.down("xs")]: {
        width: "60%",
      },
    },
  },
  sideBarNav: {
    padding: 0,
    // [theme.breakpoints.down("sm")]: {
    //   padding: "0 10px",
    // },
  },
  sidebarNavLi: {
    padding: 0,
    [theme.breakpoints.down("xl")]: {
      margin: "0 0 20px",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "0 0 0px",
    },
    [theme.breakpoints.up("xl")]: {
      margin: "0 0 18px",
    },
  },
  sidebarNavItemIcon: {
    lineHeight: 0,
    "& svg": {
      height: "auto",
      width: "16px",
      color: "#083f85",
    },
  },
  sidebarNavItem: {
    display: "flex",
    alignItems: "center",
    background: "#ffffff",
    color: "#747474",
    width: "100%",
    textDecoration: "none",
    cursor: "pointer",
    [theme.breakpoints.down("xl")]: {
      padding: "12px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: 10,
      margin: "5px 0",
      borderRadius: 0,
    },
    [theme.breakpoints.down("xs")]: {
      justifyContent: "left",
      flexDirection: "row",
      border: "none",
      padding: "5px 15px",
    },
    "&:hover": {
      color: "#ffffff",
      background: "#083f85",
      "& .nav-icon-wrap": {
        // background: "#ffffff",
        "& i": {
          color: "#083f85",
        },
      },
    },
  },
  activeLink: {
    color: "#ffffff",
    background: "#083f85",
    "& .nav-icon-wrap": {
      // background: "#ffffff",
      "& i": {
        color: "#083f85",
      },
    },
  },
  sidebarNavItemText: {
    fontSize: "10px",
    fontFamily: "Roboto",
    fontWeight: "400",
    display: "Block",
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
      fontWeight: 500,
      marginLeft: 10,
    },
  },
  iconWrap: {
    height: "32px",
    width: "32px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "100%",
    background: "#f6f6f6",
    [theme.breakpoints.down("xl")]: {
      margin: "0 0 6px",
    },
    [theme.breakpoints.up("xl")]: {
      margin: "0 0 8px",
    },
    [theme.breakpoints.down("xs")]: {
      background: "#083f85",
    },
  },
  midbarContentWrapper: {
    [theme.breakpoints.down("xs")]: {
      padding: "0 10px",
    },
  },
  headerMid: {
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      alignItems: "baseline",
    },
  },
  headingTitle: {
    display: "flex",
    alignItems: "baseline",
  },
}));

function SidebarNavItem({ path, itemName, icon }) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleSidebarSelection = () => {
    dispatch(toggleSideBar(false));
    history.push(path);
  };

  return (
    <NavLink
      className={classes.sidebarNavItem}
      to={path}
      onClick={handleSidebarSelection}
      activeClassName={classes.activeLink}
    >
      <div className={`${classes.iconWrap} nav-icon-wrap`}>
        <i className={`${classes.sidebarNavItemIcon}`}>{icon}</i>
      </div>
      <span className={classes.sidebarNavItemText}>{itemName}</span>
    </NavLink>
  );
}

function SidebarWithMidbar({
  display,
  openDrawer,
  handleClose,
  setOpenDrawer,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  let { pathname } = useLocation();
  const didMount = useRef(false);
  const parentRef = useRef(null);
  const history = useHistory();

  const handlePageChange = () => {
    dispatch(changePage(1));
    dispatch(changeOrderBy("latest"));
  };

  useEffect(() => {
    if (didMount.current) {
      setOpenDrawer(false);
    } else didMount.current = true;
  }, [setOpenDrawer, pathname]);

  if (!display) return null;

  const handleLogout = (event) => {
    dispatch(toggleLogoutModal());
  };

  const handleCTA = () => {
    history.push("/dashboard");
  };

  return (
    <Drawer
      variant="temporary"
      className={classes.siteAside}
      open={openDrawer}
      onClose={handleClose}
      classes={{
        paperAnchorDockedLeft: classes.sidebarLeftSide,
        paper: classes.paperProps,
      }}
    >
      <div ref={parentRef} className={classes.infiniteScrollerDiv}>
        <i
          className={classes.sidebarLogo}
          onClick={handleCTA}
          aria-hidden="true"
        >
          <img src={Logo} alt="Everucred Logo" width="41" height="47" />
        </i>
        <List className={classes.sideBarNav}>
          <ListItem className={classes.sidebarNavLi}>
            <SidebarNavItem
              path="/dashboard"
              itemName="Dashboard"
              icon={<Dashboard />}
              handleClose={handleClose}
            />
          </ListItem>
          <ListItem className={classes.sidebarNavLi} onClick={handlePageChange}>
            <SidebarNavItem
              path="/credentials"
              itemName="Credentials"
              icon={<Credentials />}
              handleClose={handleClose}
            />
          </ListItem>
          <ListItem className={classes.sidebarNavLi} onClick={handlePageChange}>
            <SidebarNavItem
              path="/issuers"
              itemName="Issuers"
              icon={<Issuers />}
              handleClose={handleClose}
            />
          </ListItem>
          <ListItem className={classes.sidebarNavLi} onClick={handlePageChange}>
            <SidebarNavItem
              path="/subjects"
              itemName="Subjects"
              icon={<Templates />}
              handleClose={handleClose}
            />
          </ListItem>
          <ListItem className={classes.sidebarNavLi} onClick={handlePageChange}>
            <SidebarNavItem
              path="/holders"
              itemName="Holders"
              icon={<HolderDidIcon />}
              handleClose={handleClose}
            />
          </ListItem>
          <ListItem className={classes.sidebarNavLi} onClick={handlePageChange}>
            <SidebarNavItem
              path="/activities"
              itemName="Logs"
              icon={<Logs />}
              handleClose={handleClose}
            />
          </ListItem>
          <ListItem className={classes.sidebarNavLi}>
            <SidebarNavItem
              path="/settings"
              itemName="Settings"
              icon={<Settings />}
              handleClose={handleClose}
            />
          </ListItem>
          <ListItem className={classes.sidebarNavLi}>
            <SidebarNavItem
              path="/about"
              itemName="About"
              icon={<About />}
              handleClose={handleClose}
            />
          </ListItem>
          <ListItem className={classes.sidebarNavLi} onClick={handleLogout}>
            <div className={classes.sidebarNavItem}>
              <div className={`${classes.iconWrap} nav-icon-wrap`}>
                <i className={`${classes.sidebarNavItemIcon}`}>
                  <Logout />
                </i>
              </div>
              <span className={classes.sidebarNavItemText}>Logout</span>
            </div>
          </ListItem>
        </List>
      </div>
    </Drawer>
  );
}

export default React.memo(SidebarWithMidbar);
