import {
  REGISTER_BEGIN,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
} from "./register.constants";

const initialstate = {
  loading: false,
  error: "",
  response: {},
};

export default function registerReducer(state = initialstate, action) {
  switch (action.type) {
    case REGISTER_BEGIN:
      return { ...state, loading: true };
    case REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    case REGISTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
