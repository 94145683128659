import React, { useCallback } from "react";
import styles from "core/customstyle.module.css";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import CustomTooltip from "components/CustomTooltip";

const PreviewIssuedCredential = ({ name }) => {
  const handlePreviewIssuedCredential = useCallback(() => {
    const newWindow = window.open(
      `${process.env.REACT_APP_EVERYCRED_VERIFIER}/${name}`,
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null;
  }, [name]);

  return (
    <CustomTooltip title="Verify">
      <div
        className={[styles.iconWrapper, styles.redColorBackground].join(" ")}
      >
        <VisibilityOutlinedIcon
          className={styles.iconCustom}
          onClick={handlePreviewIssuedCredential}
        />
      </div>
    </CustomTooltip>
  );
};

export default React.memo(PreviewIssuedCredential);
