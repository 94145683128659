import moment from "moment";
import {
  GET_RECORDS_BEGIN,
  GET_RECORDS_SUCCESS,
  GET_RECORDS_FAILURE,
  SET_SELECTED_RECORDS,
  SET_SELECTED_ISSUER,
  SET_SELECTED_SUBJECT,
  ISSUE_CREDENTIALS_BEGIN,
  ISSUE_CREDENTIALS_FAILURE,
  ISSUE_CREDENTIALS_SUCCESS,
  CLEAR_ISSUANCE_PROCESS_STATE,
  DELETE_SELECTED_RECORD,
  GET_OPTION_LIST_BEGIN,
  GET_OPTION_LIST_SUCCESS,
  GET_OPTION_LIST_FAILURE,
  GET_UNSIGNED_BATCH_BEGIN,
  GET_UNSIGNED_BATCH_SUCCESS,
  GET_UNSIGNED_BATCH_FAILURE,
  SET_ACTIVE_STEP,
  CREATE_UNSIGNED_BATCH_BEGIN,
  CREATE_UNSIGNED_BATCH_SUCCESS,
  CREATE_UNSIGNED_BATCH_FAILURE,
  DELETE_UNSIGNED_BATCH_BEGIN,
  DELETE_UNSIGNED_BATCH_SUCCESS,
  DELETE_UNSIGNED_BATCH_FAILURE,
  SET_VALID_FROM,
  SET_VALID_UNTIL,
} from "./issuanceProcess.constant";

const initState = {
  issuerId: "",
  subjectId: "",
  activeStep: 0,
  selectedSubject: null,
  selectedIssuer: null,
  loading: false,
  tableResponse: {},
  error: "",
  selectedRecords: [],
  response: "",
  list: {
    response: [],
    error: "",
    loading: false,
  },
  unsignedBatch: {
    response: {},
    error: "",
    loading: false,
  },
  deleteUnsignedBatch: {
    response: {},
    error: "",
    loading: false,
  },
  valid_from: moment().format("YYYY-MM-DDTkk:mm"),
  valid_until: "",
};

const credentialsIssuanceReducer = (state = initState, action) => {
  switch (action.type) {
    case CLEAR_ISSUANCE_PROCESS_STATE:
      return initState;
    case SET_ACTIVE_STEP:
      return {
        ...state,
        activeStep: action.payload,
      };
    case SET_SELECTED_ISSUER:
      return {
        ...state,
        selectedIssuer: action.payload,
        issuerId: action.payload?.id,
      };
    case SET_SELECTED_SUBJECT:
      return {
        ...state,
        selectedSubject: action.payload,
        subjectId: action.payload?.id,
      };
    case GET_RECORDS_BEGIN:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case GET_RECORDS_SUCCESS:
      return {
        ...state,
        loading: false,
        tableResponse: action.payload,
        error: "",
      };
    case GET_RECORDS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        tableResponse: {},
      };
    case SET_SELECTED_RECORDS:
      return {
        ...state,
        selectedRecords: action.payload,
      };
    case ISSUE_CREDENTIALS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ISSUE_CREDENTIALS_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    case ISSUE_CREDENTIALS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_SELECTED_RECORD:
      return {
        ...state,
        selectedRecords: state.selectedRecords.filter(
          (id) => id !== action.payload
        ),
      };

    case GET_OPTION_LIST_BEGIN:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
        },
      };
    case GET_OPTION_LIST_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          response: action.payload,
        },
      };
    case GET_OPTION_LIST_FAILURE:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error: action.payload,
        },
      };
    case GET_UNSIGNED_BATCH_BEGIN:
      return {
        ...state,
        unsignedBatch: {
          ...state.unsignedBatch,
          loading: true,
        },
      };
    case GET_UNSIGNED_BATCH_SUCCESS:
      return {
        ...state,
        unsignedBatch: {
          ...state.unsignedBatch,
          loading: false,
          response: action.payload,
        },
      };
    case GET_UNSIGNED_BATCH_FAILURE:
      return {
        ...state,
        unsignedBatch: {
          ...state.unsignedBatch,
          loading: false,
          error: action.payload,
        },
      };
    case CREATE_UNSIGNED_BATCH_BEGIN:
      return {
        ...state,
        unsignedBatch: {
          ...state.unsignedBatch,
          loading: true,
        },
      };
    case CREATE_UNSIGNED_BATCH_SUCCESS:
      return {
        ...state,
        unsignedBatch: {
          ...state.unsignedBatch,
          loading: false,
          response: action.payload,
        },
      };
    case CREATE_UNSIGNED_BATCH_FAILURE:
      return {
        ...state,
        unsignedBatch: {
          ...state.unsignedBatch,
          loading: false,
          error: action.payload,
        },
      };
    case DELETE_UNSIGNED_BATCH_BEGIN:
      return {
        ...state,
        deleteUnsignedBatch: {
          ...state.deleteUnsignedBatch,
          loading: true,
        },
      };
    case DELETE_UNSIGNED_BATCH_SUCCESS:
      return {
        ...state,
        unsignedBatch: {
          ...state.unsignedBatch,
          response: {},
        },
        deleteUnsignedBatch: {
          ...state.deleteUnsignedBatch,
          loading: false,
          response: action.payload,
        },
      };
    case DELETE_UNSIGNED_BATCH_FAILURE:
      return {
        ...state,
        deleteUnsignedBatch: {
          ...state.deleteUnsignedBatch,
          loading: false,
          error: action.payload,
        },
      };
    case SET_VALID_FROM:
      return {
        ...state,
        valid_from: action.payload,
      };
    case SET_VALID_UNTIL:
      return {
        ...state,
        valid_until: action.payload,
      };
    default:
      return state;
  }
};

export default credentialsIssuanceReducer;
