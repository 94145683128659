import {
  LIST_FETCH_BEGIN,
  LIST_FETCH_SUCCESS,
  LIST_FETCH_FAILURE,
  UPDATE_MIDBAR_LIST,
  CHANGE_ORDER_BY,
  FETCH_MORE_LIST_ITEM_BEGIN,
  FETCH_MORE_LIST_ITEM_SUCCESS,
  FETCH_MORE_LIST_ITEM_FAILURE,
  CHANGE_PAGE,
  CHANGE_ROW_PER_PAGE,
  SET_MIDBAR_PARENT_REF,
  SET_MAIN_CONTENT_REF,
} from "./midbar.constant";

const initState = {
  loading: false,
  data: {},
  error: "",
  orderBy: "latest",
  page: 1,
  rowPerPage: 7,
  midBarParentRef: null,
  mainContentRef: null,
};

const midBarReducer = (state = initState, action) => {
  switch (action.type) {
    case LIST_FETCH_BEGIN:
      return {
        ...state,
        loading: true,
        data: {},
      };
    case LIST_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case LIST_FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_MORE_LIST_ITEM_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case FETCH_MORE_LIST_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: "",
      };
    case FETCH_MORE_LIST_ITEM_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_MIDBAR_LIST:
      return {
        ...state,
        data: action.payload,
      };
    case CHANGE_ORDER_BY:
      return {
        ...state,
        orderBy: action.payload,
        page: 1,
      };
    case CHANGE_PAGE:
      return {
        ...state,
        page: action.payload,
      };
    case CHANGE_ROW_PER_PAGE:
      return {
        ...state,
        rowPerPage: action.payload,
      };
    case SET_MIDBAR_PARENT_REF:
      return {
        ...state,
        midBarParentRef: action.payload,
      };
    case SET_MAIN_CONTENT_REF:
      return {
        ...state,
        mainContentRef: action.payload,
      };
    default:
      return state;
  }
};
export default midBarReducer;
