import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  footer: {
    textAlign: "center",
    color: "#11141a",
    padding: "10px 0",
    position: "fixed",
    width: "100%",
    bottom: 0,
    backgroundColor: "#fff",
    zIndex: 1,
    "& p": {
      padding: "0",
      margin: "0",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
    },
  },
}));

function Footer({ display }) {
  const classes = useStyles();
  if (!display) return null;
  return (
    <footer className={classes.footer}>
      <Typography component="p" variant="body1">
        &#169; {new Date().getFullYear()} Everycred.com | Terms &amp; Conditions
        | Privacy Policy
      </Typography>
    </footer>
  );
}

export default React.memo(Footer);
