import {
  GET_USER_DETAILS_BEGIN,
  GET_USER_DETAILS_FAILURE,
  GET_USER_DETAILS_SUCCESS,
  SET_USER_DETAILS,
  GET_RECENTLY_VERIFIED_RECORD_BEGIN,
  GET_RECENTLY_VERIFIED_RECORD_SUCCESS,
  GET_RECENTLY_VERIFIED_RECORD_FAILURE,
  SET_NEW_NOTIFICATION,
  GET_NOTIFICATION_LIST_BEGIN,
  GET_NOTIFICATION_LIST_SUCCESS,
  GET_NOTIFICATION_LIST_FAILURE,
  SET_PARAMETER,
  READ_NOTIFICATIONS_BEGIN,
  READ_NOTIFICATIONS_SUCCESS,
  READ_NOTIFICATIONS_FAILURE,
} from "./header.constant";
import { http, headers, uniqueArray } from "utils";

const getUserDetailsBegin = () => ({ type: GET_USER_DETAILS_BEGIN });

const getUserDetailsSuccess = (payload) => ({
  type: GET_USER_DETAILS_SUCCESS,
  payload,
});

const getUserDetailsFailure = (payload) => ({
  type: GET_USER_DETAILS_FAILURE,
  payload,
});

export const getUserDetails = () => (dispatch) => {
  dispatch(getUserDetailsBegin());
  http("get", "/user", null, { headers }, true)
    .then((response) => {
      dispatch(getUserDetailsSuccess(response.data.data));
    })
    .catch((err) => {
      dispatch(getUserDetailsFailure(err.response?.data?.message));
    });
};

export const setUserDetails = (payload) => ({
  type: SET_USER_DETAILS,
  payload,
});
const getRecentlyVerifiedRecordBegin = () => ({
  type: GET_RECENTLY_VERIFIED_RECORD_BEGIN,
});

const getRecentlyVerifiedRecordSuccess = (payload) => ({
  type: GET_RECENTLY_VERIFIED_RECORD_SUCCESS,
  payload,
});
const getRecentlyVerifiedRecordFailure = (payload) => ({
  type: GET_RECENTLY_VERIFIED_RECORD_FAILURE,
  payload,
});

export const getRecentlyVerifiedRecords = (params) => (dispatch) => {
  dispatch(getRecentlyVerifiedRecordBegin());
  http("get", "/user/credential/verified", null, { headers, params }, true)
    .then((response) => {
      dispatch(getRecentlyVerifiedRecordSuccess(response.data.data));
    })
    .catch((err) => {
      dispatch(getRecentlyVerifiedRecordFailure());
    });
};

export const setNewNotificationList = (payload) => ({
  type: SET_NEW_NOTIFICATION,
  payload,
});

// export const setNotification = (data) => (dispatch, getState) => {
//   const notificationData = getState().notification.notificationList;
//   const recipientDetails = getState().common.recipientDetails.response;
//   if (recipientDetails.email === data.email) {
//     let newArray = [...new Set([data, ...notificationData.response?.data])];
//     let payload = {
//       data: newArray,
//       pages: Math.ceil(
//         (notificationData?.response?.total + 1) / notificationData?.perPage
//       ),
//       total: notificationData?.response?.total + 1,
//     };
//     dispatch(setNewNotificationList(payload));
//   }
// };

export const setParameter = (payload) => ({ type: SET_PARAMETER, payload });

const getNotificationListBegin = () => ({
  type: GET_NOTIFICATION_LIST_BEGIN,
});

const getNotificationListSuccess = (payload) => ({
  type: GET_NOTIFICATION_LIST_SUCCESS,
  payload,
});

const getNotificationListFailure = (payload) => ({
  type: GET_NOTIFICATION_LIST_FAILURE,
  payload,
});

export const getNotificationList = (params) => (dispatch, getState) => {
  const notificationData = getState().notification.notificationList;

  dispatch(getNotificationListBegin());
  http(
    "get",
    "/holder/credential/notification",
    null,
    { headers, params },
    true
  )
    .then((response) => {
      let newArray = uniqueArray(
        [
          ...(notificationData.response?.data || []),
          ...response.data.data.data,
        ],
        Object.keys(response.data.data.data[0])
      );
      let payload = {
        data: newArray || [],
        pages: response.data.data.pages || 1,
        total: response.data.data.total || 0,
      };
      dispatch(getNotificationListSuccess(payload));
    })
    .catch((err) => {
      dispatch(getNotificationListFailure(err.response?.data?.message));
    });
};

// Read Notification

const readNotificationBegin = () => ({
  type: READ_NOTIFICATIONS_BEGIN,
});

const readNotificationSuccess = (payload) => ({
  type: READ_NOTIFICATIONS_SUCCESS,
  payload,
});

const readNotificationFailure = (payload) => ({
  type: READ_NOTIFICATIONS_FAILURE,
  payload,
});

export const readNotification = (body, successCB) => (dispatch) => {
  dispatch(readNotificationBegin());
  http("put", "/holder/notification/status", body, { headers }, true)
    .then((response) => {
      dispatch(readNotificationSuccess(response.data.message));
      successCB(body);
    })
    .catch((err) => {
      dispatch(readNotificationFailure(err.response?.data?.message));
    });
};
