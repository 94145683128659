import { LOGIN_BEGIN, LOGIN_SUCCESS, LOGIN_FAILURE } from "./Login.constant";

const initState = {
  normalLogin: {
    loading: false,
    token: "",
    error: "",
  },
};

const loginReducer = (state = initState, action) => {
  switch (action.type) {
    case LOGIN_BEGIN:
      return {
        ...state,
        normalLogin: {
          ...state.normalLogin,
          loading: true,
        },
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        normalLogin: {
          ...state.normalLogin,
          loading: false,
          token: action.payload,
          error: "",
        },
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        normalLogin: {
          ...state.normalLogin,
          loading: false,
          error: action.payload,
          token: "",
        },
      };

    default:
      return state;
  }
};

export default loginReducer;
