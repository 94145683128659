import dashBoardConfig from "pages/DashBoard/dashBoardConfig";
import loginConfig from "pages/Login/loginConfig";
import noMatchConfig from "pages/NoMatch/noMatchConfig";
import registerConfig from "pages/Register/registerConfig";
import forgetPasswordconfig from "pages/ForgetPassword/forgetPasswordconfig";
import resetPasswordConfig from "pages/ResetPassword/resetPassword.config";
import credentialsConfig from "pages/Credentials/credentialsConfig";
import aboutConfig from "pages/About/aboutConfig";
import issuersConfig from "pages/Issuers/issuersConfig";
import settingsConfig from "pages/Settings/settingsConfig";
import subjectConfig from "pages/Subjects/subjectsConfig";
import logsConfig from "pages/Logs/logsConfig";
import issuanceProcessConfig from "pages/IssuanceProcess/issuanceConfig";
import verifyEmailConfig from "pages/VerifyEmail/verifyEmailConfig";
import holdersConfig from "pages/Holders/holders.config";

const routes = [
  loginConfig,
  dashBoardConfig,
  registerConfig,
  forgetPasswordconfig,
  resetPasswordConfig,
  credentialsConfig,
  issuersConfig,
  subjectConfig,
  settingsConfig,
  aboutConfig,
  logsConfig,
  issuanceProcessConfig,
  verifyEmailConfig,
  holdersConfig,
  // GlobalSearchConfig,
  noMatchConfig,
];

export default routes;
