import React from "react";
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    userNameImageWrap: {
        display: "flex",
        alignItems: "center",
      },
      userName: {
        textAlign: "right",
        marginRight: "15px",     
        [theme.breakpoints.down("sm")]: {
          display: "none",
        },
      },
      greetings:{
        maxWidth:100,
        marginLeft:100,
        height:14,
        marginBottom:10,
        borderRadius:4,
      },
      name:{
        minWidth:200,
        height:16,
        borderRadius:4,
      },
      profileImage: {
        width: 45,
        height: 45,
        borderRadius:"50%",
      },
  }));

const BreadCrumbs = () => {
    const classes = useStyles();
    return (
        <div className={classes.userNameImageWrap}>
            <div className={classes.userName}>
                <Skeleton animation="wave" variant="rect" className={classes.greetings}/>
                <Skeleton animation="wave" variant="rect" className={classes.name}/>
            </div>
            <div className={classes.profileImage}>
                <Skeleton animation="wave" variant="circle" className={classes.profileImage}/>
            </div>
        </div>
    );
};

export default BreadCrumbs;