import { uniqueArray } from "utils";
import {
  GET_MORE_SUBJECTS_BEGIN,
  GET_MORE_SUBJECTS_FAILURE,
  GET_MORE_SUBJECTS_SUCCESS,
  GET_RECORDS_BEGIN,
  GET_RECORDS_FAILURE,
  GET_RECORDS_SUCCESS,
  GET_SUBJECT_LIST_BEGIN,
  GET_SUBJECT_LIST_FAILURE,
  GET_SUBJECT_LIST_SUCCESS,
  SELECT_SUBJECT_ID,
  SET_UPDATED_SUBJECTS_LIST,
} from "./credentials.constants";

const initState = {
  subjectId: "",
  tableData: {
    error: "",
    loading: false,
    response: {},
  },
  subjectList: {
    response: {},
    loading: false,
    error: "",
  },
};

export const credentialsReducer = (state = initState, action) => {
  switch (action.type) {
    case SELECT_SUBJECT_ID:
      return {
        ...state,
        subjectId: action.payload,
      };
    case GET_RECORDS_BEGIN:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          error: "",
          loading: true,
        },
      };
    case GET_RECORDS_SUCCESS:
      return {
        ...state,
        tableData: {
          error: "",
          loading: false,
          response: action.payload,
        },
      };
    case GET_RECORDS_FAILURE:
      return {
        ...state,
        tableData: {
          error: action.payload,
          loading: false,
          response: {},
        },
      };
    case GET_SUBJECT_LIST_BEGIN:
      return {
        ...state,
        subjectList: {
          ...state.subjectList,
          error: "",
          loading: true,
          response: [],
        },
      };
    case GET_SUBJECT_LIST_SUCCESS:
      return {
        ...state,
        subjectList: {
          error: "",
          loading: false,
          response: action.payload,
        },
      };
    case GET_SUBJECT_LIST_FAILURE:
      return {
        ...state,
        subjectList: {
          error: action.payload,
          loading: false,
          response: {},
        },
      };
    case GET_MORE_SUBJECTS_BEGIN:
      return {
        ...state,
        subjectList: {
          ...state.subjectList,
          error: "",
          loading: true,
          response: {},
        },
      };
    case GET_MORE_SUBJECTS_SUCCESS:
      return {
        ...state,
        subjectList: {
          ...state.subjectList,
          error: "",
          loading: false,
          response: {
            ...state.subjectList.response,
            data: {
              ...state.subjectList.response.data,
              data:
                uniqueArray(
                  [...state.subjectList.response.data.data, ...action.payload],
                  Object.keys(action.payload[0])
                ) || [],
            },
          },
        },
      };
    case GET_MORE_SUBJECTS_FAILURE:
      return {
        ...state,
        subjectList: {
          error: action.payload,
          loading: false,
        },
      };
    case SET_UPDATED_SUBJECTS_LIST:
      return {
        ...state,
        subjectList: {
          ...state.subjectList,
          response: {
            ...state.subjectList.response,
            data: action.payload,
          },
        },
      };
    default:
      return state;
  }
};
