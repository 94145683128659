import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  midbarCardsContainer: {
    width: "100%",
  },
  midbarCard: {
    borderRadius: 10,
    marginBottom: 15,
    minHeight: 115,
    width: "93%",
    [theme.breakpoints.down("sm")]: {
      flexBasis: "48%",
      maxWidth: "48%",
      minHeight: 80,
    },
    [theme.breakpoints.down("xs")]: {
      flexBasis: "100%",
      maxWidth: "100%",
    },
  },
}));

const MidBarList = () => {
  const classes = useStyles();
  return (
    // <div className={classes.midbarCardsContainer}>
    <>
      <Skeleton
        className={classes.midbarCard}
        animation="wave"
        variant="rect"
      />
      <Skeleton
        className={classes.midbarCard}
        animation="wave"
        variant="rect"
      />
      <Skeleton
        className={classes.midbarCard}
        animation="wave"
        variant="rect"
      />
      <Skeleton
        className={classes.midbarCard}
        animation="wave"
        variant="rect"
      />
      <Skeleton
        className={classes.midbarCard}
        animation="wave"
        variant="rect"
      />
      <Skeleton
        className={classes.midbarCard}
        animation="wave"
        variant="rect"
      />
      <Skeleton
        className={classes.midbarCard}
        animation="wave"
        variant="rect"
      />
      <Skeleton
        className={classes.midbarCard}
        animation="wave"
        variant="rect"
      />
      <Skeleton
        className={classes.midbarCard}
        animation="wave"
        variant="rect"
      />
      <Skeleton
        className={classes.midbarCard}
        animation="wave"
        variant="rect"
      />
    </>
    // </div>
  );
};

export default MidBarList;
