import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import { Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import RecordTable from "../Table";

const useStyles = makeStyles({
  container: {
    maxHeight: "90vh",
    "&::-webkit-scrollbar": {
      width: "3.5px",
      backgroundColor: "#f5f5f5",
    },
    "&::-webkit-scrollbar-track": {
      backgroundClip: "content-box",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#616161",
      borderRadius: "10px",
    },
  },
  drawerBody: {
    padding: "0 18px",
    width: 800,
    "@media only screen and (max-width:768px)": {
      width: 300,
    },
  },

  drawerHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "20px 0",
    "& p": {
      fontSize: "20px",
      fontWeight: "800",
      color: "#083f85",
    },
    "& svg": {
      cursor: "pointer",
    },
  },
  tablehead: {
    borderRadius: "3px",
    backgroundColor: "#f2f2f2",
    "& th": {
      color: " #616161",
      fontWeight: "550",
    },
  },
  tableBody: {
    padding: "20px 10px",
    border: "0",
  },
  cancelBtn: {
    width: 44,
    height: 44,
    backgroundColor: "#083f85",
    color: "#fff",
    borderRadius: "50%",
    textAlign: "center",
  },
  closeIcon: {
    margin: "10px 0",
  },
});

const VerfiedDrawer = ({ open, toggleDrawer }) => {
  const classes = useStyles();
  return (
    <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
      <div className={classes.drawerBody}>
        <div className={classes.drawerHeader}>
          <Typography variant="body1">Recently Verified</Typography>
          <div
            className={classes.cancelBtn}
            onClick={toggleDrawer(false)}
            aria-hidden="true"
          >
            <CloseIcon fontSize="medium" className={classes.closeIcon} />
          </div>
        </div>
        <RecordTable />
      </div>
    </Drawer>
  );
};

export default React.memo(VerfiedDrawer);
