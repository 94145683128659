import {
  GET_USER_DETAILS_BEGIN,
  GET_USER_DETAILS_FAILURE,
  GET_USER_DETAILS_SUCCESS,
  SET_USER_DETAILS,
  GET_RECENTLY_VERIFIED_RECORD_BEGIN,
  GET_RECENTLY_VERIFIED_RECORD_SUCCESS,
  GET_RECENTLY_VERIFIED_RECORD_FAILURE,
  SET_NEW_NOTIFICATION,
  GET_NOTIFICATION_LIST_BEGIN,
  GET_NOTIFICATION_LIST_SUCCESS,
  GET_NOTIFICATION_LIST_FAILURE,
  SET_PARAMETER,
  READ_NOTIFICATIONS_BEGIN,
  READ_NOTIFICATIONS_SUCCESS,
  READ_NOTIFICATIONS_FAILURE,
} from "./header.constant";

const initState = {
  loading: false,
  data: {},
  error: "",
};

const initialState = {
  recentlyVerified: { loading: false, data: {}, error: "" },
  notificationList: {
    loading: false,
    error: "",
    response: {},
    page: 1,
    perPage: 10,
  },
  readNotification: {
    loading: false,
    response: "",
    error: "",
  },
};

export const getUserDetailsReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_USER_DETAILS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case GET_USER_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SET_USER_DETAILS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: "",
      };
    default:
      return state;
  }
};

export const headerReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_RECENTLY_VERIFIED_RECORD_BEGIN:
      return {
        ...state,
        recentlyVerified: {
          ...state.recentlyVerified,
          loading: true,
        },
      };
    case GET_RECENTLY_VERIFIED_RECORD_SUCCESS:
      return {
        ...state,
        recentlyVerified: {
          ...state.recentlyVerified,
          loading: false,
          data: action.payload,
        },
      };
    case GET_RECENTLY_VERIFIED_RECORD_FAILURE:
      return {
        ...state,
        recentlyVerified: {
          ...state.recentlyVerified,
          loading: false,
          error: action.payload,
        },
      };
    case GET_NOTIFICATION_LIST_BEGIN:
      return {
        ...state,
        notificationList: {
          ...state.notificationList,
          loading: true,
        },
      };
    case GET_NOTIFICATION_LIST_SUCCESS:
      return {
        ...state,
        notificationList: {
          ...state.notificationList,
          loading: false,
          response: action.payload,
        },
      };
    case GET_NOTIFICATION_LIST_FAILURE:
      return {
        ...state,
        notificationList: {
          ...state.notificationList,
          loading: false,
          error: action.payload,
        },
      };
    case SET_PARAMETER:
      return {
        ...state,
        notificationList: {
          ...state.notificationList,
          page: action.payload?.page || state.notificationList.page,
          perPage: action.payload?.perPage || state.notificationList.perPage,
        },
      };
    case SET_NEW_NOTIFICATION:
      return {
        ...state,
        notificationList: {
          ...state.notificationList,
          response: {
            ...state.notificationList.response,
            data: action.payload.data,
            pages: action.payload.pages,
            total: action.payload.total,
          },
        },
      };

    case READ_NOTIFICATIONS_BEGIN:
      return {
        ...state,
        readNotification: {
          ...state.readNotification,
          loading: true,
        },
      };
    case READ_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        readNotification: {
          ...state.readNotification,
          loading: false,
          response: action.payload,
        },
      };
    case READ_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        readNotification: {
          ...state.readNotification,
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};
