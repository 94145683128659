const toHex = (num) => {
  return "0x" + num.toString(16);
};

export const getChains = {
  ethereum_mainnet: {
    chainId: toHex(1),
    chainName: "Ethereum Mainnet",
    nativeCurrency: {
      name: "Ether",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://mainnet.infura.io/v3/"],
    blockExplorerUrls: ["https://etherscan.io/"],
  },
  ethereum_sepolia: {
    chainId: toHex(11155111),
    chainName: "Ethereum Sepolia",
    nativeCurrency: {
      name: "Ether",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://rpc.sepolia.org"],
    blockExplorerUrls: ["https://sepolia.etherscan.io/"],
  },
  polygon_mainnet: {
    chainId: toHex(137),
    chainName: "Polygon Mainnet",
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18,
    },
    rpcUrls: ["https://polygon-rpc.com/"],
    blockExplorerUrls: ["https://polygonscan.com/"],
  },
  // polygon_testnet: {
  //   chainId: toHex(80002),
  //   chainName: "Polygon Amoy Testnet",
  //   nativeCurrency: {
  //     name: "MATIC",
  //     symbol: "MATIC",
  //     decimals: 18,
  //   },
  //   rpcUrls: ["https://rpc-amoy.polygon.technology/"],
  //   blockExplorerUrls: ["https://www.oklink.com/amoy‍/"],
  // },
  polygon_amoy: {
    chainId: toHex(80002),
    chainName: "Polygon Amoy Testnet",
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18,
    },
    rpcUrls: ["https://rpc-amoy.polygon.technology/"],
    blockExplorerUrls: ["https://www.oklink.com/amoy‍/"],
  },
};

const testNets = [{
  title: "Ethereum Sepolia",
  value: "ethereum_sepolia",
  disable: false,
  selected: false,
},
// {
//   title: "Polygon Testnet",
//   value: "polygon_testnet",
//   disable: false,
//   selected: false,
// },
{
  title: "Polygon Amoy Testnet",
  value: "polygon_amoy",
  disable: false,
  selected: false,
},
]

export const w3cModes =
  process.env.REACT_APP_CAN_SHOW_MAINNET === "true"
    ? [
      {
        title: "Select any mode",
        value: "",
        disable: true,
        selected: false,
      },

      {
        title: "Ethereum Mainnet",
        value: "ethereum_mainnet",
        disable: false,
        selected: false,
      },

      {
        title: "Polygon Mainnet",
        value: "polygon_mainnet",
        disable: false,
        selected: false,
      },
      ...testNets,
      // {
      //   title: "CREDChain",
      //   value: "evrc_chain",
      //   disable: false,
      //   selected: false,
      // },
    ]
    : [
      {
        title: "Select any mode",
        value: "",
        disable: true,
        selected: false,
      },
      ...testNets
    ];
