import authRoles from "core/auth/authRoles";
import { lazy } from "react";
const ResetPassword = lazy(() => import("./ResetPassword"));

const resetPasswordConfig = {
  component: ResetPassword,
  path: "/reset/:token",
  exact: true,
  layout: {
    config: {
      navbar: {
        display: false,
      },
      sidebar: {
        display: false,
      },
      midbar: {
        display: false,
      },
      footer: {
        display: false,
      },
    },
  },
  auth: authRoles.anyOne,
  protected: false,
};

export default resetPasswordConfig;
