import { updateMidbarList } from "components/layout/Midbar/midbar.action";
import { openNotification } from "store/commonActions";
import { http, headers } from "utils";

import {
  GET_SUBJECT_INFO_BEGIN,
  GET_SUBJECT_INFO_SUCCESS,
  GET_SUBJECT_INFO_FAILURE,
  SET_SUBJECT_INFO,
  GET_CREDENTIAL_LIST_FOR_SUBJECT_BEGIN,
  GET_CREDENTIAL_LIST_FOR_SUBJECT_SUCCESS,
  GET_CREDENTIAL_LIST_FOR_SUBJECT_FAILURE,
  ADD_RECORD_FOR_SUBJECT_BEGIN,
  ADD_RECORD_FOR_SUBJECT_SUCCESS,
  ADD_RECORD_FOR_SUBJECT_FAILURE,
  UPLOAD_RECORD_FOR_SUBJECT_BEGIN,
  UPLOAD_RECORD_FOR_SUBJECT_SUCCESS,
  UPLOAD_RECORD_FOR_SUBJECT_FAILURE,
  GET_ALL_RECORD_FOR_SUBJECT_BEGIN,
  GET_ALL_RECORD_FOR_SUBJECT_SUCCESS,
  GET_ALL_RECORD_FOR_SUBJECT_FAILURE,
  GET_ISSUER_LIST_BEGIN,
  GET_ISSUER_LIST_FAILURE,
  GET_ISSUER_LIST_SUCCESS,
} from "./subjectDetails.constants";

// Get Subject Info
export const getSubjectInfoBegin = () => ({
  type: GET_SUBJECT_INFO_BEGIN,
});

export const getSubjectInfoSuccess = (payload) => ({
  type: GET_SUBJECT_INFO_SUCCESS,
  payload,
});

export const getSubjectInfoFailure = (payload) => ({
  type: GET_SUBJECT_INFO_FAILURE,
  payload,
});

export const getSubjectInfo = (subject_id) => (dispatch, getState) => {
  let { data } = getState().midBar;
  dispatch(getSubjectInfoBegin());

  http("get", `/user/subject/${subject_id}`, null, { headers }, true)
    .then(function (response) {
      let index = data.list.findIndex(
        (element) => element.id === response.data.data.id
      );
      let newArr = [...data.list];
      newArr[index] = response.data.data;
      let payload = {
        ...data,
        list: newArr,
      };
      dispatch(updateMidbarList(payload));
      dispatch(getSubjectInfoSuccess(response.data.data));
    })
    .catch(function (error) {
      dispatch(getSubjectInfoFailure(error?.response?.data?.message));
    });
};

export const setSubjectInfo = (payload) => ({
  type: SET_SUBJECT_INFO,
  payload,
});

// Get Credential List For Specific Subject
export const getCredentialListForSubjectBegin = () => ({
  type: GET_CREDENTIAL_LIST_FOR_SUBJECT_BEGIN,
});

export const getCredentialListForSubjectSuccess = (payload) => ({
  type: GET_CREDENTIAL_LIST_FOR_SUBJECT_SUCCESS,
  payload,
});

export const getCredentialListForSubjectFailure = (payload) => ({
  type: GET_CREDENTIAL_LIST_FOR_SUBJECT_FAILURE,
  payload,
});

export const getCredentialListForSubject =
  (params, successCB, failureCB) => (dispatch) => {
    dispatch(getCredentialListForSubjectBegin());
    http("get", "/user/subject/credentials", null, { headers, params }, true)
      .then(function (response) {
        dispatch(getCredentialListForSubjectSuccess(response.data));
        successCB(response.data);
      })
      .catch(function (error) {
        dispatch(
          getCredentialListForSubjectFailure(error?.response?.data?.message)
        );
        failureCB(error?.response?.data?.message);
      });
  };

// Add Record for Specific Subject
export const addRecordForSubjectBegin = () => ({
  type: ADD_RECORD_FOR_SUBJECT_BEGIN,
});

export const addRecordForSubjectSuccess = (payload) => ({
  type: ADD_RECORD_FOR_SUBJECT_SUCCESS,
  payload,
});

export const addRecordForSubjectFailure = (payload) => ({
  type: ADD_RECORD_FOR_SUBJECT_FAILURE,
  payload,
});

export const addRecordForSubject =
  (subject_id, data, successCB, failureCB) => (dispatch) => {
    dispatch(addRecordForSubjectBegin());

    http(
      "post",
      `/user/subject/record?subject_id=${subject_id}`,
      data,
      { headers },
      true
    )
      .then(function (response) {
        dispatch(addRecordForSubjectSuccess(response.data.data));
        dispatch(getSubjectInfo(subject_id));
        dispatch(
          openNotification({
            message: "Record has been added successfully!",
          })
        );
        successCB(response.data.data);
      })
      .catch(function (error) {
        dispatch(addRecordForSubjectFailure(error));
        failureCB(error);
      });
  };

// Upload Record (CSV file) for specific subject
export const uploadRecordForSubjectBegin = () => ({
  type: UPLOAD_RECORD_FOR_SUBJECT_BEGIN,
});

export const uploadRecordForSubjectSuccess = (payload) => ({
  type: UPLOAD_RECORD_FOR_SUBJECT_SUCCESS,
  payload,
});

export const uploadRecordForSubjectFailure = (payload) => ({
  type: UPLOAD_RECORD_FOR_SUBJECT_FAILURE,
  payload,
});

export const uploadRecordForSubject =
  (subject_id, file, successCB, failureCB) => (dispatch) => {
    dispatch(uploadRecordForSubjectBegin());

    http(
      "post",
      `/user/subject/upload/records?subject_id=${subject_id}`,
      file,
      { headers },
      true
    )
      .then(function (response) {
        dispatch(uploadRecordForSubjectSuccess(response.data));
        dispatch(getSubjectInfo(subject_id));
        dispatch(
          openNotification({ message: "Successfully uploaded Records!" })
        );
        successCB(response.data);
      })
      .catch(function (error) {
        dispatch(uploadRecordForSubjectFailure(error?.response?.data?.message));
        failureCB(error?.response?.data?.message);
      });
  };

//Get all records for specific subject
export const getAllRecordsForSubjectBegin = () => ({
  type: GET_ALL_RECORD_FOR_SUBJECT_BEGIN,
});

export const getAllRecordsForSubjectSuccess = (payload) => ({
  type: GET_ALL_RECORD_FOR_SUBJECT_SUCCESS,
  payload,
});

export const getAllRecordsForSubjectFailure = (payload) => ({
  type: GET_ALL_RECORD_FOR_SUBJECT_FAILURE,
  payload,
});

export const getAllRecordsForSubject =
  (subject_id, successCB, failureCB) => (dispatch) => {
    dispatch(getAllRecordsForSubjectBegin());

    http(
      "get",
      `/user/subject/records?subject_id=${subject_id}`,
      null,
      { headers },
      true
    )
      .then(function (response) {
        dispatch(getAllRecordsForSubjectSuccess(response.data.data));
        successCB(response.data.data);
      })
      .catch(function (error) {
        dispatch(getAllRecordsForSubjectFailure(error));
        failureCB(error);
      });
  };

// Get list of issuer for add candidate and csv upload modals
const getIssuerListBegin = () => ({ type: GET_ISSUER_LIST_BEGIN });
const getIssuerListSuccess = (payload) => ({
  type: GET_ISSUER_LIST_SUCCESS,
  payload,
});

const getIssuerListFailure = (payload) => ({
  type: GET_ISSUER_LIST_FAILURE,
  payload,
});

export const getIssuerList = (url, SuccessCB) => (dispatch) => {
  dispatch(getIssuerListBegin());
  http("get", url, null, { headers }, true)
    .then((response) => {
      dispatch(getIssuerListSuccess(response.data.data));
      SuccessCB(response.data.data);
    })
    .catch((err) => {
      dispatch(getIssuerListFailure(err));
    });
};
