import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { logout, toggleLogoutModal } from "store/commonActions";
import { useHistory } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  main: {
    padding: "46px 20px",
    width: 500,
    textAlign: "center",
    borderRadius: 10,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  subTitle: {
    color: "#535353",
    lineHeight: "29px",
    margin: "50px 0 30px",
  },
  actionBtn: {
    width: 166,
    height: 40,
    border: "1px solid #d5d5d5",
    margin: "0 20px 0 0",
    backgroundColor: "#f6f6f6",
    color: "#7e7e7e",
    [theme.breakpoints.down("sm")]: {
      width: 100,
      margin: "0 10px",
    },
  },
  actionBtnNo: {
    width: 166,
    height: 40,
    border: "1px solid #d5d5d5",
    margin: "0 20px 0 0",
    backgroundColor: "#083f85",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#083f85",
      color: "#fff",
    },
    [theme.breakpoints.down("sm")]: {
      width: 100,
      margin: "0 10px",
    },
  },
  closeIconWrapper: {
    top: 5,
    right: 10,
    fontWeight: "bold",
    position: "absolute",
    [theme.breakpoints.down("sm")]: {
      top: 0,
      right: 5,
    },
  },
}));

function LogOutModal() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { show } = useSelector((state) => state.logout);
  const history = useHistory();
  const socketConnection = useSelector((state) => state.socket.newSocket);
  const handleLogOut = () => {
    dispatch(logout());
    socketConnection?.close();
    history.push("/");
  };

  const handleClose = () => {
    dispatch(toggleLogoutModal());
  };

  return (
    <Dialog open={show} onClose={handleClose}>
      <Paper className={classes.main}>
        <IconButton className={classes.closeIconWrapper} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        <Typography variant="h4" color="secondary">
          Log Out
        </Typography>
        <Typography variant="subtitle1" className={classes.subTitle}>
          Are you sure you want to logout?
        </Typography>
        <div>
          <Button className={classes.actionBtn} onClick={handleLogOut}>
            Yes
          </Button>
          <Button className={classes.actionBtnNo} onClick={handleClose}>
            NO
          </Button>
        </div>
      </Paper>
    </Dialog>
  );
}

export default LogOutModal;
