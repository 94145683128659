import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { closeNotification } from "store/commonActions";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  snackbarPosition: {
    marginTop: "80px",
  },
}));

export default function NotificationBar(props) {
  const classes = useStyles();

  const reduxState = useSelector((state) => state.common);
  const dispatch = useDispatch();
  const { open, message, hideDuration, severity } = reduxState;

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(closeNotification());
  };

  return (
    <>
      {open ? (
        <div className={classes.root}>
          <Snackbar
            open={open}
            onClose={handleClose}
            autoHideDuration={
              hideDuration || (severity === "error" ? 6000 : 4000)
            }
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            classes={{ root: classes.snackbarPosition }}
          >
            <Alert onClose={handleClose} severity={severity || "success"}>
              {message}
            </Alert>
          </Snackbar>
        </div>
      ) : null}
    </>
  );
}
