import authRoles from "core/auth/authRoles";
import { lazy } from "react";
const Holders = lazy(() => import("./Holders"));

const holdersConfig = {
  component: Holders,
  path: "/holders",
  exact: false,
  layout: {
    config: {
      navbar: {
        display: true,
      },
      sidebar: {
        display: true,
      },
      midbar: {
        display: true,
      },
      footer: {
        display: true,
      },
    },
  },
  auth: authRoles.organization,
  protected: true,
};

export default holdersConfig;
