export const SET_SUBJECT_ID = "SET_SUBJECT_ID";
export const SET_ISSUER_ID = "SET_ISSUER_ID";

export const SET_SELECTED_SUBJECT = "SET_SELECTED_SUBJECT";
export const SET_SELECTED_ISSUER = "SET_SELECTED_ISSUER";

export const GET_RECORDS_BEGIN = "GET_RECORDS_BEGIN";
export const GET_RECORDS_SUCCESS = "GET_RECORDS_SUCCESS";
export const GET_RECORDS_FAILURE = "GET_RECORDS_FAILURE";

export const SET_SELECTED_RECORDS = "SET_SELECTED_RECORDS";

export const ISSUE_CREDENTIALS_BEGIN = "ISSUE_CREDENTIALS_BEGIN";
export const ISSUE_CREDENTIALS_SUCCESS = "ISSUE_CREDENTIALS_SUCCESS";
export const ISSUE_CREDENTIALS_FAILURE = "ISSUE_CREDENTIALS_FAILURE";

export const CLEAR_ISSUANCE_PROCESS_STATE = "CLEAR_ISSUANCE_PROCESS_STATE";

export const DELETE_SELECTED_RECORD = "DELETE_SELECTED_RECORD";

export const GET_OPTION_LIST_BEGIN = "GET_OPTION_LIST_BEGIN";
export const GET_OPTION_LIST_SUCCESS = "GET_OPTION_LIST_SUCCESS";
export const GET_OPTION_LIST_FAILURE = "GET_OPTION_LIST_FAILURE";

export const GET_UNSIGNED_BATCH_BEGIN = "GET_UNSIGNED_BATCH_BEGIN";
export const GET_UNSIGNED_BATCH_SUCCESS = "GET_UNSIGNED_BATCH_SUCCESS";
export const GET_UNSIGNED_BATCH_FAILURE = "GET_UNSIGNED_BATCH_FAILURE";

export const SET_ACTIVE_STEP = "SET_ACTIVE_STEP";

export const CREATE_UNSIGNED_BATCH_BEGIN = "CREATE_UNSIGNED_BATCH_BEGIN";
export const CREATE_UNSIGNED_BATCH_SUCCESS = "CREATE_UNSIGNED_BATCH_SUCCESS";
export const CREATE_UNSIGNED_BATCH_FAILURE = "CREATE_UNSIGNED_BATCH_FAILURE";

export const DELETE_UNSIGNED_BATCH_BEGIN = "DELETE_UNSIGNED_BATCH_BEGIN";
export const DELETE_UNSIGNED_BATCH_SUCCESS = "DELETE_UNSIGNED_BATCH_SUCCESS";
export const DELETE_UNSIGNED_BATCH_FAILURE = "DELETE_UNSIGNED_BATCH_FAILURE";

export const SET_VALID_FROM = "SET_VALID_FROM";
export const SET_VALID_UNTIL = "SET_VALID_UNTIL";

