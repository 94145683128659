import MomentUtils from "@date-io/moment";
import { ThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import "assets/css/style.css";
import Fallback from "components/Fallback";
import LogOutModal from "components/LogOutModal/LogOutModal";
import NotificationBar from "components/NotificationBar";
import routes, { AppRoute } from "core/routes";
import theme from "core/Theme";
import { Suspense, useEffect } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";

function App() {
  useEffect(() => {
    (function (c, l, a, r, i, t, y) {
      c[a] =
        c[a] ||
        function () {
          (c[a].q = c[a].q || []).push(arguments);
        };
      t = l.createElement(r);
      t.async = 1;
      t.src = "https://www.clarity.ms/tag/" + i;
      y = l.getElementsByTagName(r)[0];
      y.parentNode.insertBefore(t, y);
    })(window, document, "clarity", "script", "e0u65gicro");
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Router>
          <Suspense fallback={<Fallback />}>
            <NotificationBar />
            <LogOutModal />
            <Switch>
              {routes.map((route) => (
                <AppRoute key={route.path} {...route} />
              ))}
            </Switch>
          </Suspense>
        </Router>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
}

export default App;
