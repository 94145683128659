import authRoles from "core/auth/authRoles";
import { lazy } from "react";
const Settings = lazy(() => import("./Settings"));

const settingsConfig = {
  component: Settings,
  path: "/settings",
  exact: false,
  layout: {
    config: {
      navbar: {
        display: true,
      },
      sidebar: {
        display: true,
      },
      midbar: {
        display: true,
      },
      footer: {
        display: true,
      },
    },
  },
  auth: authRoles.anyOne,
  protected: true,
};

export default settingsConfig;
